import { ItemTypes } from '../../../../../@types'
import { useRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaCrown } from 'react-icons/fa6'

const Item = (item: ItemTypes.ModelUserAPI) => {

    const textRef = useRef<HTMLParagraphElement>(null)
    const [cutText, setCutText] = useState('')

    useEffect(() => {
        const textContainer = textRef.current
        if (textContainer) {
            const lineHeight = Number(window.getComputedStyle(textContainer).getPropertyValue('line-height').replace('px', '')) || 20
            const fontSize = Number(window.getComputedStyle(textContainer).getPropertyValue('font-size').replace('px', '')) || 18
            const blockWidth = textContainer.clientWidth
            const blockHeight = textContainer.clientHeight
        
            const proposals = item.description.split(/[.\n]/)
            console.log(proposals)

            let newText = ''
            let currentWidth = 0
            let currentHeight = lineHeight

            for (let i = 0; i < proposals.length; i++) {
                if (!proposals[i].trim()) {
                    continue
                }

                let words = proposals[i].split(' ')

                let newProposal = ''
                let isBreak = false
                for (let j = 0; j < words.length; j++) {
                    let wordWidth = words[j].length * fontSize

                    if (currentWidth + wordWidth <= blockWidth) {
                        newProposal += words[j] + ' '
                        currentWidth += wordWidth
                    } else {
                        newProposal += words[j] + ' '
                        currentWidth = wordWidth
                        currentHeight += lineHeight

                        if (currentHeight > blockHeight && i > 0) {
                            isBreak = true
                            break
                        }
                    }
                }

                if (!isBreak) {
                    newText += newProposal.trim() + '. '
                }

                if (currentHeight > blockHeight) {
                    break
                }
            }
        
            setCutText(newText)
        }
    }, [textRef])

    return (
        <Link to={`/model/${item.id}`} className="sectionItem">
            <div className='imageWrapper'>
                <div className='image' style={{backgroundImage: `url("${item.images[0].url || ''}")`}}>
                    {
                        item.isVIP ? (
                            <div className='crown'><FaCrown/></div>
                        ): null
                    }
                </div>
            </div>
            <div className="sectionInfo">
                <h4>{item.name}</h4>
                {/* <p>{item.description}</p> */}
                <p ref={textRef}>
                    {cutText}
                </p>
            </div>
        </Link>
    )
}

export default Item