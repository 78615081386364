import { ItemTypes } from "../../../../../@types"
import { Helmet } from "react-helmet"
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import Utils from "../../utils"

// Componnets
import Components from "../../components"
// Css
import './index.css'

const Item = () => {

    const params = useParams()
    
    const [isLoad, setIsLoad] = useState<boolean>(true)
    const [item, setItem] = useState<ItemTypes.ModelUserAPI>()

    useEffect(() => {
        new Promise(async () => {
            if (!params.itemId) {
                return setIsLoad(false)
            }

            const apiResult = await Utils.API.request.itemsGet({ id: params.itemId })
            if (apiResult?.success) {
                setItem(apiResult.item)
            }

            await new Promise(resolve => setTimeout(resolve, 500))
            setIsLoad(false)
        }).catch(err => { })
    }, [ ])

    return (
        <div className="itemWrapper page">
            <Helmet>
                <title>Caramel Lounge | {item?.name || 'Модель'}</title>
            </Helmet>
            <Components.Preloader isShow={isLoad} isFullScreen={true}/>
            <div className="itemView leftWrapper">
                <div className="nameWrapper">
                    <h1 className="name">{item?.name}</h1>
                </div>
                <Components.ImagesSlider images={item?.images || []}/>
                <div className="descriptionWrapper">
                    <p className="description">{item?.description}</p>
                </div>
            </div>
            <div className="itemView rightWrapper">
                <div className="stickyWrapper sectionWrapper">
                    <div className="nameWrapper">
                        <p className="name">{item?.name}</p>
                    </div>
                    <div className="createdWrapper">
                        <div className="createdText">
                            Добавлена на сайт {
                                new Date((item?.created || 0) * 1e3)
                                    .toLocaleString('ru', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: '2-digit'
                                    })
                            }
                        </div>
                    </div>
                    <div className="buttonsWrapper">
                        <a href={item?.links?.telegram || ''} className="button caramel" target="_blank">Написать в Telegram</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Item