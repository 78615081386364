import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { IoClose, IoList } from 'react-icons/io5'
import { FaCrown } from 'react-icons/fa6'

// Components 
import Components from '..'

// Css
import './index.css'

const Header = () => {
    const [isOpen, setIsOpen] = useState(false)

    const clickMenu = () => setIsOpen(!isOpen)

    return (
        <div className={`headerWrapper ${isOpen ? 'menu': ''}`}>
            <div className="header">
                <Link to='/' className="logo" onClick={() => setIsOpen(false)}>
                    <div className='text'><span className='caramel'>Caramel</span> Lounge</div>
                </Link>
                <div className='links'>
                    <Link to="/models">Модели</Link>
                    <Link to="/models?isVIP=true">
                        VIP Модели <span className='crown'><FaCrown/></span>
                    </Link>
                    <Link to="/faq">FAQ</Link>
                </div>
                <div className="buttons">
                    <Link to="/form" className="button caramel">Создать анкету</Link>
                </div>
                <div className='menuButton button' onClick={clickMenu}>
                    {
                        isOpen ? (<IoClose/>): (<IoList/>)
                    }
                </div>
            </div>
            <div className="menuContent">
                <div className='links'>
                    <Link to="/" onClick={clickMenu}>Главная</Link>
                    <Link to="/models" onClick={clickMenu}>Модели</Link>
                    <Link to="/models?isVIP=true" onClick={clickMenu}>
                        VIP Модели <span className='crown'><FaCrown/></span>
                    </Link>
                    <Link to="/faq" onClick={clickMenu}>FAQ</Link>
                </div>
                <div className="buttons">
                    <Link to="/form" className="button caramel" onClick={clickMenu}>Создать анкету</Link>
                </div>
            </div>
        </div>
    )
}

export default Header