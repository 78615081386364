import { ItemTypes } from '../../../../../@types'
import { useEffect, useRef, useState } from 'react'
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa6'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Slider from 'react-slick'

// Css
import './index.css'

type PropsTypes = {
    images: ItemTypes.Model['images']
}

const SlickButtonFix = ({
    className,
    currentSlide,
    slideCount,
    children,
    ...props
}: any) => (
    <div className={className} {...props}>
        {children}
    </div>
)

const ImagesSlider = (props: PropsTypes) => {

    const { images } = props

    const [activeImageId, setActiveImageId] = useState<string>(images[0]?.id)
    // Slider ref
    const sliderRef = useRef(null)

    useEffect(() => {
        !activeImageId && setActiveImageId(images[0]?.id)
    }, [props.images])

    const activeImage = images.find(image => image.id === activeImageId)

    const goToIndex = (index: number) => {
        const slider = sliderRef.current as any
        if (slider && slider.slickGoTo) {
            slider.slickGoTo(index)
            setActiveImageId(images[index].id)
        }
    }

    return (
        <div className="imagesSliderWrapper">
            <div
                className={`imagesSlider ${images.length === 1 ? 'oneImage': ''}`}
                style={{'--background-image': `url('${activeImage?.url}')`} as any}
            >
                {
                    <Slider
                        ref={sliderRef}
                        dots={false}
                        infinite={true}
                        speed={500}
                        slidesToShow={1}
                        slidesToScroll={1}
                        prevArrow={
                            <SlickButtonFix className="slick-arrow slick-prev">
                                <FaArrowLeft/>
                            </SlickButtonFix>
                        }
                        nextArrow={
                            <SlickButtonFix className="slick-arrow slick-next">
                                <FaArrowRight/>
                            </SlickButtonFix>
                        }
                        beforeChange={(current, next) => setActiveImageId(images[next].id)}
                    >
                        {
                            images.map((image, index) => (
                                <Zoom
                                    key={index}
                                    classDialog='zoomImageWrapper'
                                >
                                    <img src={image.url} alt="preview image"/>
                                </Zoom>
                            ))
                        }
                    </Slider>
                }
            </div>
            {
                images.length > 1 ? (
                    <div className="previewsWrapper">
                        {
                            images.map((image, index) => (
                                <div
                                    key={index}
                                    className={`preview ${image.id === activeImageId ? 'active': ''}`}
                                    onClick={() => goToIndex(index)}
                                >
                                    <img src={image.url} alt="preview image" />
                                </div>
                            ))
                        }
                    </div>
                ): null
            }
        </div>
    )
}

export default ImagesSlider