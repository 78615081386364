import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// Pages
import Pages from './pages'
// Components
import Components from "./components"

const App = () => {

    return (
        <Router>
            <div className="app">
                <Components.Header/>
                <div className="wrapper">
                    <Routes>
                        <Route path='/' element={<Pages.About/>}/>
                        <Route path='/models/' element={<Pages.Items/>}/>
                        <Route path='/model/:itemId' element={<Pages.Item/>}/>
                        <Route path='/form' element={<Pages.Form/>}/>
                        <Route path='/faq' element={<Pages.FAQ/>}/>
                        <Route path='*' element={<Pages.Error/>}/>
                    </Routes>
                </div>
                <Components.Footer/>
            </div>
        </Router>
    )
}

export default App