import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import './styles/variables.css'
import './styles/styles.css'
import './styles/animation.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <App/>
)