import { CSSTransition } from 'react-transition-group'
import { useRef } from 'react'

// Css
import './index.css'

const Preloader = (props: { isShow: boolean, isFullScreen?: boolean }) => {

    const nodeRef = useRef(null)

    return (
        <CSSTransition
            in={props.isShow}
            nodeRef={nodeRef}
            timeout={300}
            unmountOnExit
        >
            <div ref={nodeRef} className={`preloaderWrapper ${props.isFullScreen ? 'isFullScreen': ''}`}>
                <div className='preloader'></div>
            </div>
        </CSSTransition>
    )
}

export default Preloader