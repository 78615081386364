import Config from '../../config'
import { useState, ChangeEvent } from "react"
import Helmet from 'react-helmet'
import Utils from '../../utils'

// Componnets
import Components from "../../components"

import './index.css'

const Form = () => {
    
    const usernameRegExp    = /^[a-zA-Z0-9_]{5,32}$/
    const linkRegExp        = /^(?:http[s]?:\/\/)?t.me\/([A-Za-z0-9_]{5,32})$/

    const [isLoad, setIsLoad]       = useState<boolean>(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)

    const [usernameOrLink, setusernameOrLink]   = useState<string>('')
    const [name, setName]                       = useState<string>('')
    const [alert, setAlert]                     = useState<string>('')

    const enterName = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
        setAlert('')
    }

    const enterUsername = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace('@', '')

        setusernameOrLink(value)
        setAlert('')

        if (!usernameRegExp.test(value) && !linkRegExp.test(value)) {
            setAlert('Некорректный юзернейм или ссылка')
        }
    }

    const sendForm = async () => {
        if (name.length < 2) {
            return setAlert('Ваше имя не может быть меньше 2 символов')
        } else if (name.length > 32) {
            return setAlert('Ваше имя не может быть больше 32 символов')
        } else if (usernameRegExp.test(usernameOrLink) && usernameOrLink.length < 5) {
            return setAlert('Имя пользователя Telegram не может быть меньше 5 символов')
        } else if (usernameRegExp.test(usernameOrLink) && usernameOrLink.length > 35) {
            return setAlert('Имя пользователя Telegram не может быть больше 32 символов')
        } else if (!usernameRegExp.test(usernameOrLink) && !linkRegExp.test(usernameOrLink)) {
            return setAlert('Некорректный юзернейм или ссылка')
        }

        setIsLoad(true)

        const apiRResult = await Utils.API.request.formsCreate({ name, usernameOrLink })
        console.log(apiRResult)
        if (!apiRResult?.success) {
            setAlert('Произошла ошибка, попробуйте еще раз')
        } else {
            setIsSuccess(true)
        }

        await new Promise(resolve => setTimeout(resolve, 500))
        setIsLoad(false)
    }

    return (
        <div className="formWrapper page">
            <Helmet>
                <title>Caramel Lounge | Создание анкеты</title>
            </Helmet>
            <Components.Preloader isShow={isLoad} isFullScreen={true}/>
            {
                !isSuccess ? (
                    <div className="sectionWrapper">
                        <h3>Создать свою анкету на сайте</h3>
                        <p>
                            Заполните форму ниже, и наш менеджер свяжется с вами в ближайшее время. Или сами напишите нам в <a href={Config.telegram.support} target="_blank">Telegram</a>.
                        </p>
                        <div className="form">
                            <label>Ваше имя</label>
                            <input type="text" placeholder="Введите имя" value={name} onChange={enterName}/>
                            <label>Юзернейм или ссылка Telegram</label>
                            <input type="text" placeholder="Введите юзернейм или ссылку" value={usernameOrLink} onChange={enterUsername}/>
                            <span className="alert">{alert}</span>
                            <button className="button caramel" onClick={sendForm}>Отправить</button>
                        </div>
                    </div>
                ): (
                    <div className="alertWrapper alertSuccess">
                        <h1>Успешно</h1>
                        <div className="line"/>
                        <p>Анкета отправлена, скоро с вами свяжутся</p>
                    </div>
                )
            }
        </div>
    )
}

export default Form