// Css
import './index.css'

type PropsTypes = {
    className?: string
    itemId?: string
    label?: string
    items: {
        id: string
        value: string
    }[]
    onChange: (id: string) => any
}

const Tumbler = (props: PropsTypes) => {
    return (
        <div className={`sectionTumbler ${props.className || ''}`}>
            {props.label && (<label>{props.label}</label>)}
            <div className='tumblers'>
                {
                    props.items.map((item, index) => (
                        <div
                            key={index}
                            className={`sectionItem ${item.id === props.itemId ? 'active': ''}`}
                            onClick={() => props.onChange(item.id)}
                        >
                            {item.value}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Tumbler