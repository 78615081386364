import { Link } from 'react-router-dom'

// Components
import Components from '..'

import './index.css'

const Footer = () => {

    return (
        <div className='footerWrapper'>
            <div className="line"></div>
            <div className='footer'>
                &copy; 2024 Caramel
            </div>
        </div>
    )
}

export default Footer