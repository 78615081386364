import HeaderComponent from './Header'
import FooterComponent from './Footer'
import PreloaderComponent from './Preloader'
import ImagesSliderComponent from './ImagesSlider'
import TumblerComponent from './Tumbler'
import ItemComponent from './Item'

export {
    HeaderComponent,
    FooterComponent,
    PreloaderComponent,
    ImagesSliderComponent,
    TumblerComponent,
    ItemComponent
}

export default {
    Header: HeaderComponent,
    Footer: FooterComponent,
    Preloader: PreloaderComponent,
    ImagesSlider: ImagesSliderComponent,
    Tumbler: TumblerComponent,
    Item: ItemComponent
}