import Config from '../../config'
import { useState, useRef } from "react"
import { RiArrowDownSLine } from 'react-icons/ri'
import Helmet from 'react-helmet'

// Componnets
import Components from "../../components"

import './index.css'

const QuestionComponent = ({
    title,
    description
}: {
    title: string
    description: string
}) => {
    const [isOpen, setIsOpen]   = useState(false)
    const targetDescription     = useRef<HTMLDivElement>(null)
    const targetQuestion        = useRef<HTMLDivElement>(null)

    const clickQuestion = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div
            ref={targetQuestion}
            className="question"
            style={{
                height: (isOpen && targetQuestion.current) ? `${targetQuestion.current.scrollHeight}px`: ''
            }}
        >
            <div className="title" onClick={clickQuestion}>
                <h4>{title}</h4>
                <RiArrowDownSLine style={{ transform: isOpen ? `rotate(180deg)`: '' }}/>
            </div>
            <div ref={targetDescription} className="description" dangerouslySetInnerHTML={{__html: description}}/>
        </div>
    )
}

const FAQ = () => {

    const [forFAQ, setForFAQ] = useState<'forModels' | 'forUsers'>('forUsers')

    return (
        <div className="faqWrapper page">
            <Helmet>
                <title>Caramel Lounge | FAQ</title>
            </Helmet>
            <div className="sectionWrapper">
                <h3>Часто задаваемые вопросы</h3>
                <p>
                    В этом разделе мы постарались подробно ответить на самые популярные вопросы от моделей и гостей сайта. 
                    Для вашего удобства ответы на вопросы мы разделили на два раздела «Ответы на вопросы от пользователей» и «Ответы на вопросы от моделей». 
                    Если здесь вы не нашли ответа на интересующий вас вопрос, вы можете задать его нашим менеджерам в <a href={Config.telegram.support} target='_blank'>Telegram</a>. 
                </p>
                <div className='questionsWrapper'>
                    <div className="filters">
                        <Components.Tumbler
                            itemId={forFAQ}
                            items={[
                                {
                                    id: 'forUsers',
                                    value: 'Пользователям'
                                },
                                {
                                    id: 'forModels',
                                    value: 'Моделям'
                                }
                            ]}
                            onChange={id => setForFAQ(id as any)}
                        />
                    </div>
                    {
                        forFAQ === 'forModels' ? (
                            <>
                                <QuestionComponent
                                    title='Как добавить свою анкету на сайт?'
                                    description={`Чтобы добавить свою анкету на сайт, напишите нашим менеджерами в <a href="${Config.telegram.support}" target="_blank">Telegram</a> или перейдите по <a href="/form" target="_blank">ссылке</a>.`}
                                />
                                <QuestionComponent
                                    title='Как попасть в раздел «VIP Модели» на сайте?'
                                    description={`Чтобы попасть в раздел «VIP модели», напишите нашим менеджерами в <a href="${Config.telegram.support}" target="_blank">Telegram</a>.`}
                                />
                                <QuestionComponent
                                    title='Как редактировать или удалить свою анкету?'
                                    description={`Чтобы редактировать или удалить свою анкету, напишите нашим менеджерами в <a href="${Config.telegram.support}" target="_blank">Telegram</a>.`}
                                />
                            </>
                        ): (
                            <>
                                <QuestionComponent
                                    title='Как начать видео-чат с моделью?'
                                    description={`Чтобы начать общение с моделью, нажмите на кнопку «Написать в Telegram» на странице интересующей вас модели. Чат с моделью автоматически откроется в мессенджере. Либо напишите нашим менеджерами в <a href="${Config.telegram.support}" target="_blank">Telegram</a>, общение с какой моделью вас заинтересовало.`}
                                />
                                <QuestionComponent
                                    title='Как пожаловаться на модель?'
                                    description={`	Если у вас есть возникли какие-либо претензии к модели, сообщите об этом нашим менеджерам, написав в <a href="${Config.telegram.support}" target="_blank">Telegram</a>, и мы оперативно рассмотрим Вашу жалобу и примем соотвествующие меры плоть до удаления анкеты модели с нашего сайта.`}
                                />
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default FAQ