import AboutPage from './About'
import ErrorPage from './Error'
import ItemsPage from './Items'
import ItemPage from './Item'
import FormPage from './Form'
import FAQPage from './FAQ'

export {
    AboutPage,
    ErrorPage,
    ItemPage,
    FormPage,
    FAQPage,
    ItemsPage
}

export default { 
    About: AboutPage,
    Error: ErrorPage,
    Item: ItemPage,
    Form: FormPage,
    FAQ: FAQPage,
    Items: ItemsPage
}