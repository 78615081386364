import { ServerTypes } from '../../../../@types'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

export class API {
    private axios: AxiosInstance

    constructor(
        {
            baseURL = '/api/',
            axiosParams
        }: {
            baseURL?: string
            axiosParams?: AxiosRequestConfig
        }
    ) {
        this.axios = axios.create({
            baseURL,
            ...axiosParams
        })
    }

    private async get(method: string, params?: { } | string): Promise<any> {
        let result: any
        try {
            if (params) params = new URLSearchParams(params).toString()
            result = await this.axios.get(`${method}${params ? `?${params}`: ''}`, {
                withCredentials: true
            })
            result = result?.data
        } catch(err: any) {
            result = err?.response?.data
        }

        return result || null
    }

    private async post(method: string, data: { }): Promise<any> {
        let result: any
        try {
            result = await this.axios.post(method, data, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            result = result?.data
        } catch(err: any) {
            result = err?.response?.data
        }

        return result || null
    }

    private async patch(method: string, data: { }): Promise<any> {
        let result: any
        try {
            result = await this.axios.patch(method, data, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            result = result?.data
        } catch(err: any) {
            result = err?.response?.data
        }

        return result || null
    }

    private async delete(method: string, data: { }): Promise<any> {
        let result: any
        try {
            result = await this.axios.delete(method, {
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            result = result?.data
        } catch(err: any) {
            result = err?.response?.data
        }

        return result || null
    }

    private async fileUpload(method: string, formData: FormData): Promise<any> {
        let result: any
        try {
            result = await this.axios.post(method, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            result = result?.data
        } catch(err: any) {
            result = err?.response?.data
        }

        return result || null
    }

    async itemsList(params?: ServerTypes.ApiRequest.Client.ItemsListRequest | string): Promise<ServerTypes.ApiResponse.Client.ItemsListResponse | null> {
        return await this.get('items.list', params)
    }

    async itemsGet(params: ServerTypes.ApiRequest.Client.ItemsGetRequest): Promise<ServerTypes.ApiResponse.Client.ItemResponse | null> {
        return await this.get('items.get', params)
    }

    async formsCreate(params: ServerTypes.ApiRequest.Client.FormsCreateRequest): Promise<ServerTypes.ApiResponse.StandartResponse | null> {
        return this.post('forms.create', params)
    }
}

export const request = new API({
    // baseURL: 'http://localhost:8080/api/',
    // axiosParams: {
    //     withCredentials: true
    // }
})