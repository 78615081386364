import { ItemTypes } from '../../../../../@types'
import Config from '../../config'
import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from "react-router-dom"
import { FaCrown } from "react-icons/fa6"
import Utils from '../../utils'

// Componnets
import Components from "../../components"

import './index.css'

const Items = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const [isLoad, setIsLoad]   = useState(true)
    const [items, setItems]     = useState<ItemTypes.ModelUserAPI[]>([])
    const [filters, setFilters] = useState<{
        isVIP: boolean | null
        gender: string | null
    } | null>(null)

    useEffect(() => {
        new Promise(async () => {
            !isLoad && setIsLoad(true)

            const params = new URLSearchParams(location.search)
            setFilters({
                gender: params.get('gender'),
                isVIP: params.get('isVIP') === 'true' ? true: (params.get('isVIP') === 'false' ? false: null)
            })

            if (!params.get('isDraft')) {
                params.set('isDraft', 'false')
            }

            const apiResult = await Utils.API.request.itemsList(params.toString())

            if (apiResult && apiResult.success) {
                setItems(apiResult.items)
            }

            await new Promise(resolve => setTimeout(resolve, 500))
            setIsLoad(false)
        }).catch(() => { })
    }, [location.search])

    // const updateFilters = (id: string, value: any) => {
    //     const params = new URLSearchParams(location.search)
    //     if (value !== null) {
    //         params.set(id, value)
    //     } else {
    //         params.delete(id)
    //     }

    //     navigate(`${location.pathname}?${params.toString()}`, { replace: true })
    // }

    return (
        <div className="modelsWrapper page">
            <Helmet>
                <title>Caramel Lounge | Модели</title>
            </Helmet>
            <Components.Preloader isShow={isLoad} isFullScreen={true}/>
            <div className="sectionWrapper">
                {
                    filters?.isVIP ? (
                        <h3>VIP Модели <span className='crown'><FaCrown/></span></h3>
                    ): (
                        <h3>Модели</h3>
                    )
                }
                {
                    // !filters?.isVIP ? (
                    //     <div className="filtersWrapper">
                    //         <Link to='/models' className={`filter ${!filters?.gender ? 'active': ''}`} onClick={() => updateFilters('gender', null)}>
                    //             <img src="/images/allFilterPreview.jpeg" alt="All models"/>
                    //             <p>Все модели</p>
                    //         </Link>
                    //         <Link to='/models?gender=woman' className={`filter ${filters?.gender === 'woman' ? 'active': ''}`} onClick={() => updateFilters('gender', 'woman')}>
                    //             <img src="/images/womanFilterPreview.jpeg" alt="Woman models"/>
                    //             <p>Девушки</p>
                    //         </Link>
                    //         <Link to='/models?gender=man' className={`filter ${filters?.gender === 'man' ? 'active': ''}`} onClick={() => updateFilters('gender', 'man')}>
                    //             <img src="/images/manFilterPreview.png" alt="Man models"/>
                    //             <p>Мужчины</p>
                    //         </Link>
                    //     </div>
                    // ): null
                }
                {
                    items.length > 0 ? (
                        <div className="sectionList">
                            {
                                items.map((item, index) => 
                                    <Components.Item
                                        key={index}
                                        {...item}
                                    />
                                )
                            }
                        </div>
                    ): (       
                        <div className="alertWrapper">
                            <h1>404</h1>
                            <div className="line"/>
                            <p>Модели не найдены</p>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Items