import Helmet from 'react-helmet'

// Componnets
import Components from "../../components"

import './index.css'

const NotFound = () => {
    return (
        <div className="page">
            <Helmet>
                <title>Caramel Lounge | 404</title>
            </Helmet>
            <div className="alertWrapper">
                <h1>404</h1>
                <div className="line"/>
                <p>Не найдено</p>
            </div>
        </div>
    )
}

export default NotFound