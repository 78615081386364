import { ItemTypes } from '../../../../../@types'
import Config from '../../config'
import { useState, useEffect, Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { SiTelegram } from "react-icons/si"
import Utils from '../../utils'

// Componnets
import Components from "../../components"

import './index.css'

const About = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const [isLoad, setIsLoad]   = useState(true)
    const [items, setItems]     = useState<ItemTypes.ModelUserAPI[]>([])

    useEffect(() => {
        new Promise(async () => {
            !isLoad && setIsLoad(true)

            const apiResult = await Utils.API.request.itemsList({ limit: 20 })
            if (apiResult && apiResult.success) {
                setItems(apiResult.items)
            }
            
            await new Promise(resolve => setTimeout(resolve, 500))
            setIsLoad(false)
        }).catch(() => { })
    }, [location.search])

    return (
        <div className="aboutWrapper page">
            <Helmet>
                <title>Caramel Lounge | Главная</title>
            </Helmet>
            <div className='introWrapper' style={{'--background-image': `url('/images/aboutPreview.jpeg')`} as any}>
                <div className="filtersWrapper">
                    <Link to='/models' className='filter'>
                        <img src="/images/allFilterPreview.jpeg" alt="All models"/>
                        <p>Модели</p>
                    </Link>
                    <Link to='/models?isVIP=true' className='filter'>
                        <img src="/images/womanFilterPreview.jpeg" alt="Woman models"/>
                        <p>VIP</p>
                    </Link>
                    {/* <Link to='/models?gender=woman' className='filter'>
                        <img src="/images/womanFilterPreview.jpeg" alt="Woman models"/>
                        <p>Девушки</p>
                    </Link> */}
                    {/* <Link to='/models?gender=man' className='filter'>
                        <img src="/images/manFilterPreview.png" alt="Man models"/>
                        <p>Мужчины</p>
                    </Link> */}
                </div>
                <div className="intro">
                    <h1>Модели для виртуального общения</h1>
                    <h2>Проверенно. Конфиденциально. Незабываемо</h2>
                </div>
            </div>
            <div className="itemsWrapper sectionWrapper">
                <h3>Модели</h3>
                <p>Модели, которые понравились больше всего пользователям</p>
                <div className="sectionList">
                    <Components.Preloader isShow={isLoad}/>
                    {
                        items.map((item, index) => 
                            <Components.Item
                                key={index}
                                {...item}
                            />
                        )
                    }
                </div>
            </div>
            <div className="sectionWrapper">
                <h3>О проекте Caramel</h3>
                <p>Наша цель помогать людям со всего мира находить новые знакомства, максимально сблизиться и получать удовольствие от общения друг с другом. Наша команда разработала удобный и безопасный ресурс для пользователей, которые хотят сохранить свои данные в полной конфиденциальности. Все анкеты на сайте принадлежат опытным моделям и проверены нами лично.</p>
            </div>
            <div className='contacts sectionWrapper'>
                <h3>Наши контакты</h3>
                <p>По всем имеющимся у вас вопросам вы можете связаться с нашими менеджерами, написав нам удобным для вас способом</p>
                <div className="links">
                    <a className="button" href={Config.telegram.channel} target="_blank">
                        <SiTelegram/> Канал в Telegram 
                    </a>
                    <a className="button caramel" href={Config.telegram.support} target="_blank">
                        <SiTelegram/> Написать в Telegram 
                    </a>
                </div>
            </div>
        </div>
    )
}

export default About